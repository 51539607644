<template>
  <!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
  <div id="EidtInformation" style="width:100vw">
    <div id="a14ece09">
      <div id="a53bac5a">
        <div id="a6a337e4">资讯标题</div>
        <el-input id="ad61795a" v-model="inp_ad61795a"> </el-input>
      </div>
      <div id="b6ddd150">
        <div id="ab7d3619">封面</div>
        <el-upload
          id="aefa7070"
          action="/R17771874636-PU-UPLOADSERVER-V0X0X1/pu?type=QueryData&fidx=uploadFile"
          :show-file-list="false"
          :http-request="uploadfengmianImg"
          :on-success="updsuc_aefa7070"
          :before-upload="updbefor_aefa7070"
          multiple
        >
          <img id="imgaefa7070" v-if="imgurl_aefa7070" :src="imgurl_aefa7070" />
        </el-upload>
      </div>
      <div id="b6ddd150">
        <div id="ab7d3619">文章banner图</div>
        <el-upload
          id="aefa7070"
          action="/R17771874636-PU-UPLOADSERVER-V0X0X1/pu?type=QueryData&fidx=uploadFile"
          :http-request="uploadbannerImg"
          :show-file-list="false"
          :on-success="updsuc_articleBnner"
          :before-upload="updbefor_articleBnner"
          multiple
        >
          <img
            id="imgaefa7070"
            v-if="imgurl_articleBnner"
            :src="imgurl_articleBnner"
          />
        </el-upload>
      </div>
      <div id="a6e2edb3">
        <div id="f3028657">类别</div>
        <el-select id="a85e677d" placeholder="解决方案" v-model="val_a85e677d">
          <el-option
            v-for="item in op_a85e677d"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div id="ba36965d">
        <div id="bcdff6a2">是否置顶</div>
        <el-radio-group id="a0a1c4c3" v-model="rdg_a0a1c4c3">
          <el-radio id="a49cfd50" label="是"> </el-radio>
          <el-radio id="a8f6dd6e" label="否"> </el-radio>
        </el-radio-group>
      </div>
      <div id="aa068757">
        <div id="a5564b5e">状态</div>
        <el-select id="e611eaa4" placeholder="使用" v-model="val_e611eaa4">
          <el-option
            v-for="item in op_e611eaa4"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div id="articleSummary">
        <div id="a3c7c5bc">文章摘要</div>
        {{articleTitle}}
        <textarea v-model="inp_articleSummary" id="inputtextarea"></textarea>
      </div>
      <div id="aa52d6c7">
        <div class="videolist">
          现有视频列表
          <el-button
            @click="clickaddnewvideo()"
            class="tag-read"
            style="margin-left: 300px"
            >新增视频
          </el-button>
          <el-table :data="videolist_data">
            <el-table-column prop="a687e057" label="视频名字">
            </el-table-column>
            <el-table-column prop="cc7cb36c" label="视频地址" width="300px">
            </el-table-column>
            <el-table-column prop="复制" label="复制">
              <template slot-scope="scope">
                <el-button
                  size="small"
                  :data-clipboard-text="scope.row.cc7cb36c"
                  @click="clickurl(scope.row)"
                  class="tag-read"
                  >复制
                </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="a0a8e060" label="上传时间">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div id="a6e6a122">
        <div id="a3c7c5bc">资讯内容</div>
        <!-- <div id="a6bb9f73" ref="ref_a6bb9f73">
      </div> -->
        <el-form>
          <el-form-item>
            <quill-editor
              v-model="content"
              ref="myQuillEditor"
               @ready="ready($event)"
              :options="editorOption"
              @change="onEditorChange($event)"
              class="ql-editor"
            >
            </quill-editor>
          </el-form-item>
          <form
            action
            method="post"
            enctype="multipart/form-data"
            id="uploadFormMulti"
          >
            <input
              style="display: none"
              :id="uniqueId"
              type="file"
              name="file"
              multiple
              accept="image/jpg, image/jpeg, image/png, image/gif"
              @change="uploadImg('uploadFormMulti')"
              :on-success="upScuccess"
            />
          </form>
        </el-form>
      </div>
      <div id="aa1c67e7">
        <el-button id="ac49e63e" @click="clickac49e63e()"> 保存 </el-button>
        <el-button id="d34d6360" @click="clickd34d6360()"> 取消 </el-button>
      </div>
      <div
        id="dlgaddnewvideo"
        v-show="vis_addnewvideo"
        @click.self="vis_addnewvideo = true"
      >
        <div id="addnewvideo">
          视频名字<el-input v-model="videoname" style="width: 500px"></el-input>
          <el-button size="small" type="primary" @click="clicksavenewvideo()"
            >保存视频</el-button
          >
        </div>
        <div id="d94e1f74">
          文章视频
          <el-upload
            class="myuploadVideo"
            action="/R17771874636-PU-UPLOADSERVER-V0X0X1/pu?type=QueryData&fidx=uploadFile"
            :http-request="Upload"
            :before-upload="beforeAvatarUpload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :on-exceed="handleExceed"
            :show-file-list="true"
            multiple
            :limit="1"
            :on-change="handleChange"
            :file-list="fileList"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              <em>点击上传</em>
            </div>
            <div slot="tip" class="el-upload__tip">上传文件大小不能超过 1G</div>
            <el-progress
              class="uploadProcess"
              v-show="showProgress"
              :text-inside="true"
              :stroke-width="15"
              :percentage="progress"
            ></el-progress>
          </el-upload>
        </div>
        <el-button size="small" type="primary" @click="clickcanceladd()"
          >取消</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
var fonts = ['SimSun', 'SimHei','Microsoft-YaHei','KaiTi','FangSong','Arial','Times-New-Roman','sans-serif'];
var Font = Quill.import('formats/font');
let fontSizeStyle = Quill.import('attributors/style/size')
Font.whitelist = fonts; //将字体加入到白名单
fontSizeStyle.whitelist = ["18px","20px","22px","24px","26px","28px","30px","32px","36px","40px"]
Quill.register(Font, true);
Quill.register(fontSizeStyle, true);

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {lineHeightStyle}  from '../quill/lineHeight'
import video from '../quill/video'
import Quill from "quill";
Quill.register(video,true)
import ImageResize from "quill-image-resize-module"; // 引用
import  {ImageDrop}  from "quill-image-drop-module";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize); // 注册
// 工具栏配置
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
  [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
  [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
  // [{ size: [] }],
  // [{'size': ['10px', '12px', '14px','16px','18px','20px','24px','26px','32px','48px'] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
  [{ color: [] }, { background: [] },{ size: fontSizeStyle.whitelist }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  // [{ font: [] }], // 字体种类-----[{ font: [] }]
  [{ align: [] }], // 对齐方式-----[{ align: [] }]

  ["image", "video"], // 链接、图片、视频-----['link', 'image', 'video']
  [{ lineheight: ["1", "1.5", "1.75", "2", "3", "4", "5"] }], // 对齐方式
   [{ 'font': fonts }],       //把上面定义的字体数组放进来
   ["clean"], // 清除文本格式-----['clean']
];

import {
  DALINEWEB_ARTICLECENTER_V0X0X1_updateArticle,
  DALINEWEB_ARTICLECENTER_V0X0X1_getArticleInfo,
  DALINEWEB_IMGCENTER_V0X0X1_getImgList,
  DALINEWEB_IMGCENTER_V0X0X1_addImg,
} from '../api/dalapi.js'


//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_editor from "wangeditor"
import http from "@/api/restful.js"
import { client,getFileNameUUID} from "@/api/ali-oss"; //前面的ali-js文件内的两个封装函数
import Clipboard from 'clipboard'

// let edt_ref_a6bb9f73 = null
export default{
    components:{
    quillEditor,
    },
  data(){
    return{
    inp_ad61795a:'',
    imgurl_aefa7070:'',
    op_a85e677d:[
        {value: 167,label: '私域流量建设'},
        {value: 169,label: '数字资产化'},
        {value: 41,label: '一体化业务开发平台'},
        {value: 87,label: '关于我们'},
        {value: -2,label: '新闻中心'},
        // {value: 1,label: '企业简介'},
        {value: 168,label: '私域流量建设(解决方案)'},
        {value: 170,label: '数字资产化(解决方案)'},
        {label:"公司招聘",value:2},
        {label:"产品案例",value:171}
    ],
    val_a85e677d:'',
    rdg_a0a1c4c3:'',
    rd_a49cfd50:'',
    rd_a8f6dd6e:'',
    op_e611eaa4:[
        {value: 0,label: '使用'},
        {value: 2,label: '停用'}
    ],
    val_e611eaa4:'',
    ref_a6bb9f73:'',
      //在此注释下方添加自定义变量
      videodataList:'',
      articleID:this.$route.query.articleID,
      articleInfo:'',
      inp_articleSummary:'',
           content:'',
      editorOption: { placeholder: '', theme: 'snow',
                    modules: {
                       //工具栏定义的
                    toolbar: {container:toolbarOptions,
                              handlers: {
                              lineheight: (value) => {
                                if (value) {
                                  let quill = this.$refs.myQuillEditor.quill;
                                  quill.format("lineHeight", value);
                                }
                                },
                                },
                              },
                    imageDrop: true,
                    imageResize: {
                      displayStyles: {
                        backgroundColor: "black",
                        border: "none",
                        color: "white"
                      },
                      modules: ["Resize", "DisplaySize", "Toolbar"]
                    },
                    } },
      uniqueId:'uniqueId',
      videolist_data:[],
       vis_addnewvideo:'',
        videoname:'',
        showProgress:false,
      progress:0,
      fileList:[],
      imgurl_articleBnner:''
    }
  },
  mounted(){
    this.getarticleInfo()
    this.getvideolist()
    // this.getedt_ref()
        var _this = this;
    var imgHandler = async function(image) {
      if (image) {
        var fileInput = document.getElementById(_this.uniqueId); //隐藏的file文本ID
        fileInput.click(); //加一个触发事件
      }
    };
    _this.editor.getModule("toolbar").addHandler("image", imgHandler);
  },
  computed:{
    editor() {
      return this.$refs.myQuillEditor.quill;
    }
  },
  watch:{
  },
  methods:{
    clickurl(data){
      console.log("row.cc7cb36c",data.cc7cb36c);
        let clipboard = new Clipboard('.tag-read')
        clipboard.on('success', e => {
            console.log('复制成功',e)
            //  释放内存
          // clipboard.destory()
        })
    },
    uploadfengmianImg(file){
            const that = this;
      async function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = getFileNameUUID() + "." + fileFormat;
        client()
          .multipartUpload(`DalineWeb/${fileName}`, file.file, {
            progress: function (p) {
              //p进度条的值
              console.log(p);
            },
          })
          .then((result) => {
            //上传成功返回值，可针对项目需求写其他逻辑
            // console.log("oss res",result);
            that.imgurl_aefa7070 = "https://store.daline.com.cn/prod/dalineweb/daloss/" + result.name
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
      multipartUpload();
    },
    updbefor_articleBnner(file) {
      console.log('imagefile', file)
      const isJPG = file.type === "image/jpeg" || "image/png";
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG.PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 20MB!");
      }
    },
    uploadbannerImg(file){
    const that = this;
    async function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = getFileNameUUID() + "." + fileFormat;
        client()
          .multipartUpload(`DalineWeb/${fileName}`, file.file, {
            progress: function (p) {
              //p进度条的值
              console.log(p);
            },
          })
          .then((result) => {
            //上传成功返回值，可针对项目需求写其他逻辑
            that.imgurl_articleBnner = "https://store.daline.com.cn/prod/dalineweb/daloss/" + result.name
            console.log(that.imgurl_articleBnner);
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
      multipartUpload();
    },
    updsuc_articleBnner(res, file) {
      console.log('成功回调结果', res)
      console.log('成功回调结果file', file)
      this.imgurl_articleBnner = URL.createObjectURL(file.raw);
    },
    ready() {
      Quill.register({ "formats/lineHeight": lineHeightStyle }, true);
    },
    onEditorChange({  html }) {
          //  this.content = this.content.replace("<iframe", `<iframe style="display: block; margin: auto; cursor: nesw-resize;"`)
       this.content = html
       },

    uploadImg: async function(){
         console.log("tup");
      let _this = this;
      //构造formData对象
      let fd = new FormData();
      fd.append("file",document.getElementById(_this.uniqueId).files[0] );
      // fd.append("file", document.getElementById(_this.uniqueId).files[0]);
            let uploadURL =
          "/R17771874636-PU-UPLOADSERVER-V0X0X1/pu?type=QueryData&fidx=uploadFile";
      try {
        //调用上传文件接口
        await http
          .post(uploadURL, fd, {
            headers: { "Content-Type": "multipart/form-data" },
            baseURL: "/dalgw",
          }).then(res => {
          //返回上传文件的地址
          let url = res.content.url;
          console.log(url,"diyige");
          if (url != null && url.length > 0) {
            let Range = _this.editor.getSelection();
            url = url.indexOf("http") != -1 ? url : "http:" + url;
            console.log(url,"dierge");
            //上传文件成功之后在富文本中回显(显示)
            _this.editor.insertEmbed(
              Range != null ? Range.index : 0,
              "image",
              url
            );
          } else {
            _this.$message.warning("图片上传失败");
          }
          //成功之后,将文件的文本框的value置空
          document.getElementById(_this.uniqueId).value = "";
        });
      } catch ({ message: msg }) {
        document.getElementById(_this.uniqueId).value = "";
        _this.$message.warning(msg);
      }
    },
    // 文本编辑器中
    upScuccess(res) {
        const quill = this.$refs.myQuillEditor.quill
        if (res.code === 200 && res.data !== null) {
            const length = quill.getSelection().index // 获取光标所在位置
            quill.insertEmbed(length, 'image', res.data.url) // 插入图片
            quill.setSelection(length + 1) // 调整光标位置到最后
        } else {
            this.$message.error('图片上传失败')
        }
    },

    // 保存
    async clickac49e63e(){
          if (this.articleID !== undefined) {
        let dicInput = {
          articleID: this.articleID,
          articleTitle: this.inp_ad61795a,
          articleImg: this.imgurl_aefa7070,
          top: this.rdg_a0a1c4c3 === "是" ? 1 : 0,
          status: this.val_e611eaa4 ===  "停用" ? 2:0,
          // articleContent: this.edt_ref_a6bb9f73.$textContainerElem.elems[0].innerHTML,
          articleContent: this.content
        }
        switch(this.val_a85e677d){
          case "一体化业务开发平台":
          this.val_a85e677d = 41
          break
          case "私域流量建设":
          this.val_a85e677d = 167
          break
          case "数字资产化":
          this.val_a85e677d = 169
          break
          case "关于我们":
          this.val_a85e677d = 87
          break
          case "企业简介":
          this.val_a85e677d = 1
          break
          case "新闻中心":
          this.val_a85e677d = -2
          break
          case "私域流量建设(解决方案)":
          this.val_a85e677d = 168
          break
          case "数字资产化(解决方案)":
          this.val_a85e677d = 170
          break
          case "产品案例":
          this.val_a85e677d = 171
          break
        }
        dicInput.articleType = this.val_a85e677d,
        dicInput.articleSummary = this.inp_articleSummary
        dicInput.articleBnner = this.imgurl_articleBnner
        console.log(dicInput);
        const res = await DALINEWEB_ARTICLECENTER_V0X0X1_updateArticle(dicInput,"post")
        if (res.content.resCode === 0){
          this.$message("编辑成功")
          this.vis_ac269b5e = true
          // this.$router.push('Information')
        }else{
          this.$message("编辑失败")
        }
        return
      }
      // this.$router.push('Information')
    },
    async clickd34d6360(){
      this.$router.push('Information')
    },
    //在此注释下方添加自定义函数或事件
    // 封面
    updsuc_aefa7070(res, file) {
      console.log('成功回调结果', res)
      console.log('成功回调结果file', file)
      this.imgurl_aefa7070 = URL.createObjectURL(file.raw);
    },
    async getFileURL(imgFile) {
      let fd = new FormData();
      let fileURL = "";
      fd.append("file", imgFile);
      let uploadURL =
          "/R17771874636-PU-UPLOADSERVER-V0X0X1/pu?type=QueryData&fidx=uploadFile";
       await http
          .post(uploadURL, fd, {
            headers: { "Content-Type": "multipart/form-data" },
            baseURL: "/dalgw",
          })
          .then(
              (res) => {
                console.log("上传成功111", res);
                fileURL = res.content.url;
                this.imgurl_aefa7070 = fileURL;
              },
              (err) => {
                console.log("上传失败", err);
                this.$message("上传失败");
              }
          );
          console.log(uploadURL,"auploadURL");
          return fileURL;
    },
    updbefor_aefa7070(file) {
      console.log('imagefile', file)
      const isJPG = file.type === "image/jpeg" || "image/png";
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG.PNG 格式!");
      } else {
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 20MB!");
        }
        else {
          this.getFileURL(file);
        }
      }
      return isJPG && isLt2M;
    },

    async getarticleInfo(){
     let dicInput = {articleID:this.articleID,contenteditable:1}
     const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticleInfo(dicInput)
      let resContent = res.content.resContent
     let articleInfo = resContent[0]
     console.log(articleInfo,"a");
     if (articleInfo.articleImg != "undefined") {
     this.imgurl_aefa7070 = articleInfo.articleImg
     }
     this.inp_ad61795a = articleInfo.articleTitle
     this.val_a85e677d = articleInfo.articleType
     this.rdg_a0a1c4c3 = articleInfo.top
     this.val_e611eaa4 = articleInfo.status
     this.imgurl_articleBnner = articleInfo.articleBnner
     this.inp_articleSummary = articleInfo.articleSummary
     this.content = articleInfo.articleContent
    },
    async clicksavenewvideo(){
      const res = await DALINEWEB_IMGCENTER_V0X0X1_addImg({
          imgName: 1,
          imgURL: this.imgurl,
          imgType: 1,
        });
      let addres = JSON.stringify(res.retContent)
      console.log(addres);
     },
    async clickaddnewvideo(){
      this.vis_addnewvideo = true
    },
    async clickcanceladd(){
      this.vis_addnewvideo = false
    },
    async getvideolist() {
      const resImgList = await DALINEWEB_IMGCENTER_V0X0X1_getImgList({
        imgType: 1,
      });
      const videodataList = JSON.parse(JSON.stringify(resImgList.retContent));
      this.videodataList = JSON.parse(videodataList.retContent).imgList
            let temp = []
      this.videodataList.forEach(element => {
        temp.push({
        a687e057 : element.imgName,
        cc7cb36c : element.imgURL,
        a0a8e060 : element.insertTime
        })
      });
      this.videolist_data = temp
      },
      // 文章视频
    Upload(file) {
      const that = this;
      async function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = getFileNameUUID() + "." + fileFormat;
        client()
          .multipartUpload(`Wechat-XHT/FutureSchool/${fileName}`, file.file, {
            progress: function (p) {
              //p进度条的值
              console.log(p);
              that.showProgress = true;
              that.progress = Math.floor(p * 100);
            },
          })
          .then((result) => {
            //上传成功返回值，可针对项目需求写其他逻辑
            // console.log("oss res",result);
            that.videoURL = "https://store.daline.com.cn/prod/dalineweb/daloss/" + result.name
            console.log(that.videoURL);
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
      multipartUpload();
    },
        //在此注释下方添加自定义函数或事件
    handleChange(file, fileList) {
      console.log("handleChange", file, fileList);
      this.fileList = [{ name: file.name }];
    },

    // 文件超出个数限制时的钩子
    handleExceed(files, fileList) {
      console.log("handleExceed", files, fileList);
      this.$message.warning(`每次只能上传 ${this.limit} 个文件`);
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePreview(file) {
      console.log("handlePreview", file);
    },
    // 删除文件之前的钩子
    beforeRemove(file, fileList) {
      console.log(fileList);
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.progress=0
    },
    // 文件上传成功时的钩子
    handleSuccess(response, file, fileList) {
      this.fileList = fileList;
    },
        //文件上传前的校验
    async beforeAvatarUpload(file) {
      const isLt100M =
        file.size / 1024 / 1024 > 1 && file.size / 1024 / 1024 < 1024;
      const isLt30 = file.name.length < 30;
      if (["video/mp4"].indexOf(file.type) == -1) {
        this.$message.error("请上传正确的视频格式");
        return false;
      }
      if (!isLt100M) {
        this.$message.error("上传视频大小要在10MB~1GB之间哦!");
        return false;
      }
      if (!isLt30) {
        this.$message.error("上传视频文件名称长度必须要小于30个文字哦!");
        return false;
      }
      return true},
    async getedt_ref(){
    this.edt_ref_a6bb9f73 = new dal_editor(this.$refs.ref_a6bb9f73)
    this.edt_ref_a6bb9f73.config.uploadImgMaxLength = 1
    this.edt_ref_a6bb9f73.config.zIndex = 1
    this.edt_ref_a6bb9f73.config.height = 1418
    this.edt_ref_a6bb9f73.config.showLinkVideo = false
    this.edt_ref_a6bb9f73.config.showLinkImg = false
    this.edt_ref_a6bb9f73.config.showFullScreen  = true
    this.edt_ref_a6bb9f73.config.placeholder   = ""
    this.edt_ref_a6bb9f73.config.customUploadVideo  = function (resultFiles, insertVideoFn) {
    client().put('myVideo', resultFiles[0])
      .then(function (res) {
        insertVideoFn(res.url)
        console.log("a");
      }).catch(function (err) {
        console.log(err)
      })
      }
    this.edt_ref_a6bb9f73.config.customUploadImg = function (resultFiles, insertImgFn) {
    client().put('myImg', resultFiles[0])
      .then(function (res) {
        insertImgFn(res.url)
      }).catch(function (err) {
        console.log(err)
      })
    }
    this.edt_ref_a6bb9f73.create()
    this.edt_ref_a6bb9f73.txt.html(this.$route.query.articleContent)
    },
    }
    }
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#a14ece09 {
  width: 1649px;
  position: relative;
  padding-top: -155px;
  padding-bottom: 329px;
}
#a53bac5a {
  width: 588px;
  position: relative;
  padding-left: 70px;
  padding-top: 216px;
}
#a6a337e4 {
  padding-top: 13px;
  padding-bottom: 13px;
  display: inline-block;
  vertical-align: middle;
}
#a6a337e4 {
  text-align: left;
  color: #333;
  font-size: 13px;
  font-family: PingFang SC;
}
#a53bac5a .el-input {
  width: 436px;
  margin-left: 100px;
  display: inline-block;
  vertical-align: middle;
}
#b6ddd150 {
  position: relative;
  margin-top: 38px;
  padding-left: 70px;
}
#ab7d3619 {
  width: 90px;
  padding-top: 13px;
  display: inline-block;
  vertical-align: middle;
}
#ab7d3619 {
  text-align: left;
  color: #333;
  font-size: 13px;
  font-family: PingFang SC;
}
#aefa7070 {
  width: 130px;
  height: 168px;
  margin-left: 126px;
  display: inline-block;
  vertical-align: middle;
}
#aefa7070 .el-upload {
  position: absolute;
  height: 168px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
}
#aefa7070 .el-upload:hover {
  border-color: #409eff;
}
#imgaefa7070 {
  width: 100%;
  height: 100%;
  display: block;
}
#aefa7070 .iconaefa7070 {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
#a6e2edb3 {
  width: 588px;
  position: relative;
  margin-top: 22px;
  padding-left: 70px;
}
#f3028657 {
  padding-top: 13px;
  padding-bottom: 13px;
  display: inline-block;
  vertical-align: middle;
}
#f3028657 {
  text-align: left;
  color: #333;
  font-size: 13px;
  font-family: PingFang SC;
}
#a6e2edb3 .el-select {
  width: 436px;
  margin-left: 126px;
}
#ba36965d {
  width: 328px;
  position: relative;
  margin-top: 43px;
  padding-left: 71px;
}
#bcdff6a2 {
  display: inline-block;
  vertical-align: middle;
}
#bcdff6a2 {
  text-align: left;
  color: #333;
  font-size: 13px;
  font-family: PingFang SC;
}
#a0a1c4c3 {
  width: 177px;
  margin-left: 99px;
  display: inline-block;
  vertical-align: middle;
}
#a0a1c4c3 {
  width: auto;
  display: inline-block;
  vertical-align: middle;
}
#a49cfd50 {
  width: 41px;
  display: inline-block;
  vertical-align: middle;
}
#a8f6dd6e {
  width: 41px;
  margin-left: 95px;
  display: inline-block;
  vertical-align: middle;
}
#aa068757 {
  width: 588px;
  position: relative;
  margin-top: 43px;
  padding-left: 70px;
}
#a5564b5e {
  padding-top: 13px;
  padding-bottom: 13px;
  display: inline-block;
  vertical-align: middle;
}
#a5564b5e {
  text-align: left;
  color: #333;
  font-size: 13px;
  font-family: PingFang SC;
}
#aa068757 .el-select {
  width: 436px;
  margin-left: 126px;
}
#a6e6a122 {
  width: 1218px;
  position: relative;
  margin-top: 43px;
  padding-left: 70px;
}
#a3c7c5bc {
  /* padding-bottom:182px; */
  display: inline-block;
  vertical-align: middle;
}
#a3c7c5bc {
  text-align: left;
  color: #333;
  font-size: 13px;
  font-family: PingFang SC;
}
#a6bb9f73 {
  width: 1218px;
  margin-left: 100px;
  display: inline-block;
  vertical-align: middle;
}
#aa1c67e7 {
  width: 344px;
  position: relative;
  margin-top: 60px;
  padding-left: 70px;
}
#ac49e63e {
  width: 142px;
  display: inline-block;
  vertical-align: middle;
}
#ac49e63e {
  background-color: rgb(20, 22, 32, 1);
  border-radius: 0px 0px 0px 0px;
}
#ac49e63e {
  color: #fff;
  font-size: 14px;
  font-family: PingFang SC;
  border: transparent;
}
#d34d6360 {
  width: 142px;
  margin-left: 60px;
  display: inline-block;
  vertical-align: middle;
}
#d34d6360 {
  background-color: rgb(153, 153, 153, 1);
  border-radius: 0px 0px 0px 0px;
}
#d34d6360 {
  color: #fff;
  font-size: 14px;
  font-family: PingFang SC;
  border: transparent;
}
/*在此注释下方添加自定义样式*/
#articleSummary {
  width: 500px;
  padding-left: 70px;
  padding-top: 30px;
}
#inputtextarea {
  width: 500px;
  height: 150px;
  border: solid 0.5px;
  margin-left: 150px;
}
.editor {
  line-height: normal !important;
  height: 500px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
  content: "30px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: "32px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "36px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="40px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="40px"]::before {
  content: "40px";
}


.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}

#dlgaddnewvideo {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 300;
  width: 600px;
  height: 400px;
  background: aliceblue;
}
#addnewvideo {
  margin-left: 10px;
  padding-top: 40px;
}
#addnewvideo.el-input {
  padding-left: 55px;
}
#aa52d6c7 {
  width: 800px;
  padding-left: 100px;
  padding-top: 50px;
}
/* img {
    width: 100%;
    height: 100%;
    object-fit: fill;
} */
/* img {
    width: auto;
    height: auto;
} */
.ql-toolbar.ql-snow + .ql-container.ql-snow{
  height: 80vh;
}
</style>
